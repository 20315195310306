<template>
    <DashboardContainer>
        <add-dealer />
    </DashboardContainer>
</template>

<script>
import DashboardContainer from '../../containers/DashboardContainer'
import addDealer from '../../components/admin/dealers/addDealer'

export default {
    // name:'Dealers',
    beforeCreate: function () {
        var auth = this.$storage.get('auth')
        if (!auth) {
            this.$router.push('/login')
        }else{
            if(!auth.token){
                this.$router.push('/login')
            }
        }
    },
    components: {
        DashboardContainer,
        addDealer
    }
}
</script>