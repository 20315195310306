<template>
  <div class="customMS">
    <div class="customMS-drop">
      <label v-if="label">{{ label }}</label>
      <h4 v-on:click="isActive = !isActive" style="border-radius: 30px">
        <span v-if="lsources.length == 0">{{ placeholder }}</span>
        <span
          v-if="lsources.length > 0"
          :title="lsources | customMultiSelectPreviewCheckbox(textColumn)"
          >{{ lsources | customMultiSelectPreviewCheckbox(textColumn) }}
        </span>
        <img
          class="img-fluid"
          v-if="!isActive"
          src="../../assets/img/Vector.svg"
          alt=""
        />
        <img
          class="img-fluid"
          v-if="isActive"
          src="../../assets/img/arrow-d.svg"
          alt=""
        />
      </h4>
    </div>

    <div class="customMS-opt" :class="isActive === true ? 'show-options' : ''">
      <div class="form-group customMS-search" v-if="searchable">
        <input
          type="search"
          placeholder="Search..."
          class="form-control"
          v-model="search"
        />
        <img src="/img/search.898ea790.svg" alt="" class="img-fluid Vehi-img" />
      </div>
      <simplebar style="max-height: 170px" data-simplebar-auto-hide="false">
        <input
          type="checkbox"
          v-model="check"
          class="select custom-control-input"
        />

        <label class="custom-control-label" v-if="all" @click="selectall"
          >Select all</label
        >
        <label class="custom-control-label" v-else @click="unselectall"
          >Unselect all</label
        >
        <div
          class="custom-control custom-checkbox"
          v-for="(source, i) in filteredItems"
          :key="i"
        >
          <input
            type="checkbox"
            class="custom-control-input"
            :id="id + i"
            v-on:change="updateData(source)"
            v-model="lsources"
            :value="value !== null ? source[value] : source"
          />
          <label class="custom-control-label" :for="id + i">{{
            source[textColumn]
          }}</label>
        </div>
      </simplebar>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
export default {
  name: "MultiSelect",
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    checked: Array,
    id: String,
    label: String,
    options: Array,
    placeholder: String,
    value: {
      type: String,
      default: null,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    alreadySelected: {
      type: Array,
      default: null,
    },
    textColumn: String,
  },
  components: {
    simplebar,
  },
  data() {
    return {
      search: "",
      isActive: false,
      lsources: [],
      all: true,
      check: false,
    };
  },
  created() {
    let vs = this;
    $("body").mouseup(function (e) {
      var container = $(".customMS-opt,.customMS-drop h4");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        vs.isActive = false;
      }
    });

    this.$root.$on("action", function (action) {
      if (action == "clearmultiselct") {
        vs.lsources = [];
      }
    });
  },
  methods: {
    unselectall() {
      this.all = true;
      $(".select").prop("checked", false);
      this.lsources = [];
      if (this.textColumn == "text") {
        this.$root.$emit("action", "unselectalladminleasesources");
      }
    },
    selectall() {
      this.all = false;
      $(".select").prop("checked", true);
      this.lsources = this.filteredItems;
      if (this.textColumn == "text") {
        this.$root.$emit("action", "checkalladminleasesources");
      }
    },
    updateData() {
      this.$emit("change", this.lsources);
    },
    updatall(event) {
      if (this.all) {
        this.$root.$emit("all");
      }
      if (event.target.checked == false) {
        this.$root.$emit("removeall");
        this.lsources = [];
      }
    },
  },
  computed: {
    filteredItems() {
      return this.options.filter((item) => {
        return (
          item[this.textColumn]
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  mounted() {
    if (this.alreadySelected != null) {
      this.lsources = this.alreadySelected;
    }
  },
  watch: {
    alreadySelected: function () {
      this.lsources = this.alreadySelected;
    },
  },
};
</script>


<style scoped>
.customMS,
.customMS-search {
  position: relative;
}
.customMS-search .Vehi-img {
  top: 9px !important;
}
.customMS-drop {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.customMS-drop h4 {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 6px 12px;
  padding: 6px 12px;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #343945;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d5d8df;
  border-radius: 0.25rem;
  cursor: pointer;
}
.customMS-drop h4 img {
  text-align: right;
  float: right;
  margin-top: 7px;
}
.show-options {
  display: block !important;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: absolute;
  top: 64px;
  left: 0;
  background: #fff;
  width: 100%;
  z-index: 9;
}
.customMS-opt {
  display: none;
  border: 1px solid #d5d8df;
  border-top: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: 10px;
}
.customMS-opt .custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding: 0px;
  cursor: pointer;
}
.customMS-opt .custom-control:hover {
  background: #f7f7f7;
}

.customMS-opt .custom-control-label:before {
  position: absolute;
  top: 9px;
  top: 0.55rem;
  right: 0.55rem;
  right: 10px;
  left: auto;
  display: block;
  width: 16px;
  width: 1rem;
  height: 16px;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #d5d8df solid 1px;
}
.customMS-opt .custom-control-label:after {
  position: absolute;
  top: 9px;
  top: 0.55rem;
  right: 0.55rem;
  right: 10px;
  left: auto;
  display: block;
  width: 16px;
  width: 1rem;
  height: 16px;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.customMS-opt
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label:after {
  background-image: none;
  right: 15px;
  top: 11px;
  width: 5px;
  height: 9px;
  border: 1px solid #3151a1;
  border-radius: 3px;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.customMS-opt .custom-control-input:checked ~ .custom-control-label:after {
  background-image: none;
  right: 15px;
  top: 11px;
  width: 5px;
  height: 9px;
  border: 1px solid #3151a1;
  border-radius: 3px;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.customMS-opt label {
  font-size: 14px;
  color: #343945;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 0;
  width: 100%;
  cursor: pointer;
  padding: 5px 10px;
}

.customMS-opt
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label:before {
  background-color: #dbe5ff;
  outline: inherit;
  border-color: #dbe5ff;
  box-shadow: inherit;
}
.customMS-opt .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #dbe5ff;
  outline: inherit;
  border-color: #dbe5ff;
  box-shadow: inherit;
}
</style>
